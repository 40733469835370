import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, CircularProgress, AppBar, Toolbar } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2'; // Import Bar and Pie chart components
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, ArcElement } from 'chart.js'; // Import chart.js components
import { db } from '../../../firebase/firebaseConfig'; // Firebase config
import { collection, getDocs } from 'firebase/firestore';
import { startOfWeek, startOfMonth } from 'date-fns';
import CalendarView from '../calender/calender'; // Calendar Component
import ManageLogs from '../managereports/managereports'; // Logs management
import SendNotification from '../notifications/notifications'; // Notifications
import ManageUsers from '../users/users'; // User management

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ArcElement);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [metrics, setMetrics] = useState({
    logsToday: 0,
    logsThisWeek: 0,
    logsThisMonth: 0,
    totalUsers: 0,
    newUsers: 0,
    unapprovedLogs: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);

      const logsCollection = collection(db, 'logs');
      const usersCollection = collection(db, 'Users');
      const logsSnapshot = await getDocs(logsCollection);
      const usersSnapshot = await getDocs(usersCollection);

      const logsData = logsSnapshot.docs.map(doc => doc.data());
      const usersData = usersSnapshot.docs.map(doc => doc.data());

      const today = new Date();
      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const startOfThisWeek = startOfWeek(today);
      const startOfThisMonth = startOfMonth(today);

      // Calculate Logs metrics
      const logsToday = logsData.filter(log => log.timestamp && log.timestamp.seconds * 1000 >= startOfToday.getTime()).length;
      const logsThisWeek = logsData.filter(log => log.timestamp && log.timestamp.seconds * 1000 >= startOfThisWeek.getTime()).length;
      const logsThisMonth = logsData.filter(log => log.timestamp && log.timestamp.seconds * 1000 >= startOfThisMonth.getTime()).length;
      const unapprovedLogs = logsData.filter(log => log.status === 'pending').length;

      // Calculate Users metrics
      const totalUsers = usersData.length;
      const newUsers = usersData.filter(user => user.timestamp && user.timestamp.seconds * 1000 >= startOfThisWeek.getTime()).length;

      // Set metrics in state
      setMetrics({
        logsToday,
        logsThisWeek,
        logsThisMonth,
        totalUsers,
        newUsers,
        unapprovedLogs,
      });

      setLogs(logsData);
      setUsers(usersData);
      setLoading(false);
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // Bar chart data for Logs Overview
  const logsBarChartData = {
    labels: ['Today', 'This Week', 'This Month'],
    datasets: [
      {
        label: 'Logs',
        data: [metrics.logsToday, metrics.logsThisWeek, metrics.logsThisMonth],
        backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f'],
        borderWidth: 1,
      },
    ],
  };

  // Pie chart data for User Overview
  const usersPieChartData = {
    labels: ['Total Users', 'New Users (This Week)'],
    datasets: [
      {
        label: 'Users',
        data: [metrics.totalUsers, metrics.newUsers],
        backgroundColor: ['#ffcc00', '#ff6600'], // Updated colors
        hoverBackgroundColor: ['#ffcc00', '#ff6600'],
      },
    ],
  };

  return (
    <Box sx={{ padding: '20px' }}>
      {/* Dashboard Header */}
      <AppBar position="static" color="primary" sx={{ marginBottom: '20px' }}>
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Metrics Overview */}
      <Grid container spacing={3}>
        {/* Bar Chart for Logs Overview */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: '20px', borderRadius: '12px', backgroundColor: '#141b2d' }}>
            <Typography variant="h6" gutterBottom> New Logs </Typography>
            <Bar data={logsBarChartData} />
          </Paper>
        </Grid>

        {/* Pie Chart for Users Overview */}
       
        {/* Calendar View Component */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: '20px', borderRadius: '12px', backgroundColor: '#141b2d' }}>
            <Typography variant="h6" gutterBottom>Calendar Overview</Typography>
            <CalendarView logs={logs} />
          </Paper>
        </Grid>

        {/* Manage Logs Component */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: '20px', borderRadius: '12px', backgroundColor: '#141b2d' }}>
            <Typography variant="h6" gutterBottom>Manage Logs</Typography>
            <ManageLogs />
          </Paper>
        </Grid>

        {/* Send Notification Component */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: '20px', borderRadius: '12px', backgroundColor: '#141b2d' }}>
            <Typography variant="h6" gutterBottom>Send Notification</Typography>
            <SendNotification />
          </Paper>
        </Grid>

        {/* Manage Users Component */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: '20px', borderRadius: '12px', backgroundColor: '#141b2d' }}>
            <Typography variant="h6" gutterBottom>Manage Users</Typography>
            <ManageUsers />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
