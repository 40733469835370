import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { db, auth, storage } from "../../../firebase/firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { tokens } from "../../../theme";

export const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [profileData, setProfileData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          setProfileData(docSnap.data());
        }
      });
    }
  }, []);

  const handleUpdateProfile = async () => {
    const user = auth.currentUser;
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      const updates = {};

      // Handle password update if current password and new password are provided
      if (newPassword && currentPassword) {
        try {
          const credential = EmailAuthProvider.credential(user.email, currentPassword);
          await reauthenticateWithCredential(user, credential);

          if (newPassword === confirmNewPassword) {
            await updatePassword(user, newPassword);
            updates.password = newPassword;
          } else {
            setError("New passwords do not match.");
            return;
          }
        } catch (error) {
          setError("Current password is incorrect.");
          return;
        }
      }

      // Handle photo upload
      if (photo) {
        const photoRef = ref(storage, `profilePhotos/${user.uid}`);
        try {
          await uploadBytes(photoRef, photo);
          const photoURL = await getDownloadURL(photoRef);
          updates.photoURL = photoURL; // Prepare to update photoURL in Firestore
          setSuccessMessage("Profile picture uploaded successfully!");
        } catch (uploadError) {
          setError("Error uploading photo.");
          return;
        }
      }

      // Update Firestore if there are any updates
      if (Object.keys(updates).length > 0) {
        await updateDoc(docRef, updates);
        setEditMode(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setError("");
        setSuccessMessage("Profile updated successfully!");
        setPhoto(null);
        setPhotoPreview(null); // Reset photo preview after successful update
      } else {
        setError("No changes made.");
      }
    }
  };

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        padding: "20px",
        margin: "20px 0",
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.primary[400],
          borderRadius: "8px",
          padding: "20px",
          width: { xs: "90%", sm: "400px" },
          boxSizing: "border-box",
          boxShadow: "0px 4px 20px rgba(0,0,0,0.3)",
        }}
      >
        <Typography variant="h3" fontWeight="600" color={colors.grey[100]}>
          Profile Management
        </Typography>
        <Box mt="40px">
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Name: {`${profileData.firstName || ""} ${profileData.lastName || ""}`}
          </Typography>
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Email: {profileData.email || ""}
          </Typography>
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Designation: {profileData.designation || ""}
          </Typography>
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Employee ID: {profileData.employeeId || ""}
          </Typography>
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Position: {profileData.position || ""}
          </Typography>
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Status: {profileData.status || ""}
          </Typography>
          <Typography variant="body1" color={colors.grey[300]} sx={{ fontSize: '1.1rem', marginBottom: '10px' }}>
            Role: {profileData.role || ""}
          </Typography>

          {profileData.photoURL && (
            <img
              src={profileData.photoURL}
              alt="Profile"
              width="100"
              height="100"
              style={{ borderRadius: "50%", marginTop: "10px" }}
            />
          )}

          {editMode && (
            <Box mt="20px">
              <Button variant="contained" component="label" sx={{ marginBottom: "10px" }}>
                Upload Photo
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photoPreview && (
                <img
                  src={photoPreview}
                  alt="Preview"
                  width="100"
                  height="100"
                  style={{ borderRadius: "50%", marginTop: "10px" }}
                />
              )}
              <TextField
                label="Current Password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: "10px", fontSize: '1rem' }}
              />
              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: "10px", fontSize: '1rem' }}
              />
              <TextField
                label="Confirm New Password"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: "10px", fontSize: '1rem' }}
              />
              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
              {successMessage && (
                <Typography color="success.main" variant="body2">
                  {successMessage}
                </Typography>
              )}
            </Box>
          )}
        </Box>

        <Box mt="20px">
          {editMode ? (
            <Button
              variant="contained"
              onClick={handleUpdateProfile}
              disabled={newPassword !== confirmNewPassword && newPassword !== ""}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setEditMode(true)}
              sx={{
                borderColor: colors.blueAccent[700],
                color: colors.blueAccent[700],
              }}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
