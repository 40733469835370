import React, { useState } from 'react';
import { auth } from '../../firebase/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');

        try {
            await sendPasswordResetEmail(auth, email);
            toast.success('Password reset email sent! Please check your inbox.', { position: "top-center" });
            navigate('/login'); // Redirect to login after sending email
        } catch (err) {
            console.error('Error sending password reset email:', err.message);
            setError('Failed to send password reset email. Please check the email address.');
            toast.error(err.message, { position: "bottom-center" });
        }
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h5" textAlign="center" marginBottom={2}>Forgot Password</Typography>
            <form onSubmit={handleResetPassword}>
                <TextField
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    sx={{ marginBottom: '10px' }}
                />
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                >
                    Send Reset Email
                </Button>
                {error && <Typography color="red">{error}</Typography>}
            </form>
        </Box>
    );
};

export default ForgotPassword;
