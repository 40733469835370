import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCXJXLRJ5UDk8wbzUpWVO5MqtDNVciGZ3I",
  authDomain: "atc-admin-app.firebaseapp.com",
  projectId: "atc-admin-app",
  storageBucket: "atc-admin-app.appspot.com",
  messagingSenderId: "173547718225",
  appId: "1:173547718225:web:e4ff231636c8c5699eda70"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export { auth };
