import React, { useEffect, useState } from "react";
import { db, auth } from "../../../firebase/firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";

export const ReportTracking = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const q = query(collection(db, "Reports"), where("userId", "==", user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedReports = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReports(fetchedReports);
      });
      return () => unsubscribe();
    }
  }, []);

  return (
    <div>
      <h3>Report Tracking</h3>
      <ul>
        {reports.map((report) => (
          <li key={report.id}>
            <strong>{report.title}</strong>: {report.status}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReportTracking;