import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

// Item component for individual sidebar menu items
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen size
  const [selected, setSelected] = useState("Dashboard");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userDoc = doc(db, "users", "USER_ID"); // Replace USER_ID with actual user ID
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        setUserName(userSnapshot.data().firstName);
      } else {
        console.log("No such user!");
      }
    };
    fetchUserInfo();
  }, []);

  // Floating icons for mobile view (icons only, no text)
  const floatingIcons = (
    <Box
      sx={{
        position: "fixed",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: colors.primary[400],
        display: "flex",
        justifyContent: "space-around",
        width: "80%",
        padding: "10px 20px",
        borderRadius: "30px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
      }}
    >
      <IconButton color="inherit" onClick={() => setSelected("Dashboard")} component={Link} to="/admin">
        <HomeOutlinedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={() => setSelected("Calendar View")} component={Link} to="/calendar">
        <CalendarTodayOutlinedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={() => setSelected("Manage Logs")} component={Link} to="/manage-reports">
        <ReceiptOutlinedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={() => setSelected("Send Notifications")} component={Link} to="/admin-notifications">
        <SendOutlinedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={() => setSelected("Manage Users")} component={Link} to="/users">
        <PeopleOutlinedIcon />
      </IconButton>
    </Box>
  );

  // Full sidebar for desktop view
  const desktopSidebar = (
    <Box
      sx={{
       
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
         
          position: 'fixed'
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar>
        <Menu iconShape="square">
          {/* Sidebar Header */}
          <MenuItem
            onClick={() => setSelected("Dashboard")}
            
            style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
              <Typography variant="h3" color={colors.grey[100]}>
                ADMIN DashBoard
              </Typography>
              <IconButton onClick={() => setSelected("Dashboard")}>
               
              </IconButton>
            </Box>
          </MenuItem>

          {/* Sidebar Menu Items */}
          <Item
            title="Dashboard"
            to="/admin"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Calendar View"
            to="/calendar"
            icon={<CalendarTodayOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Manage Logs"
            to="/manage-reports"
            icon={<ReceiptOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Send Notifications"
            to="/admin-notifications"
            icon={<SendOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Manage Users"
            to="/users"
            icon={<PeopleOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Menu>
      </ProSidebar>
    </Box>
  );

  return (
    <Box>
      {/* Render the sidebar for desktop, floating icons for mobile */}
      {isMobile ? floatingIcons : desktopSidebar}
    </Box>
  );
};

export default Sidebar;
