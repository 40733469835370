import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebaseConfig"; 
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button, Modal, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { format } from 'date-fns';
import { useTheme } from "@mui/material/styles";

const ManageLogs = () => {
  const theme = useTheme();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLog, setSelectedLog] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logsCollection = collection(db, "logs");
        const logSnapshot = await getDocs(logsCollection);
        const logsList = logSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        
        setLogs(logsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching logs:", error.message);
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  const handleApproveLog = async (logId) => {
    try {
      const logRef = doc(db, "logs", logId);
      await updateDoc(logRef, { status: "approved", dateApproved: new Date() });
      setLogs(logs.map(log => log.id === logId ? { ...log, status: "approved", dateApproved: new Date() } : log));
    } catch (error) {
      console.error("Error approving log:", error.message);
    }
  };

  const handleDisapproveLog = async (logId) => {
    try {
      const logRef = doc(db, "logs", logId);
      await updateDoc(logRef, { status: "pending", dateApproved: null });
      setLogs(logs.map(log => log.id === logId ? { ...log, status: "pending", dateApproved: null } : log));
    } catch (error) {
      console.error("Error disapproving log:", error.message);
    }
  };

  const openModal = (log) => {
    setSelectedLog(log);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedLog(null);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>Manage Logs</Typography>
      <Paper elevation={3} sx={{ padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date Posted</TableCell>
                <TableCell>User Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.item}</TableCell>
                  <TableCell>{log.description}</TableCell> {/* Ensure the log object has a description field */}
                  <TableCell>{log.type}</TableCell> {/* Ensure the log object has a type field */}
                  <TableCell>
                    {log.status === "approved" ? (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDisapproveLog(log.id)}
                      >
                        Disapprove
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleApproveLog(log.id)}
                      >
                        Approve
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {log.timestamp ? format(new Date(log.timestamp.seconds * 1000), 'dd/MM/yyyy HH:mm') : 'N/A'}
                  </TableCell>
                  <TableCell>{log.userEmail}</TableCell>
                  <TableCell>
                    <Button onClick={() => openModal(log)} sx={{ color: 'white' }}>
                      <ArrowForwardIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      <Modal open={modalOpen} onClose={closeModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '90%',
          maxWidth: 500,
        }}>
          <Typography variant="h6" gutterBottom>Log Details</Typography>
          {selectedLog && (
            <Box>
              <Typography><strong>Item:</strong> {selectedLog.item}</Typography>
              <Typography><strong>Description:</strong> {selectedLog.description}</Typography>
              <Typography><strong>Type:</strong> {selectedLog.type}</Typography>
              <Typography><strong>Status:</strong> {selectedLog.status}</Typography>
              <Typography><strong>Date Posted:</strong> {selectedLog.timestamp ? format(new Date(selectedLog.timestamp.seconds * 1000), 'dd/MM/yyyy HH:mm') : 'N/A'}</Typography>
              <Typography><strong>User Email:</strong> {selectedLog.userEmail}</Typography>
            </Box>
          )}
          <Button onClick={closeModal} variant="contained" color="primary" sx={{ marginTop: 2 }}>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ManageLogs;
