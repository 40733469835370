import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { db, auth } from "../../../firebase/firebaseConfig"; 
import { doc, getDoc } from "firebase/firestore";
import defaultProfilePic from './profile.jpg';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} className="menu-item-link" />
    </MenuItem>
  );
};

const UserSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [firstName, setFirstName] = useState("");
  const [photoURL, setPhotoURL] = useState("");

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getGreeting = () => {
    const hours = new Date().getHours();
    if (hours < 12) return "Good Morning";
    if (hours < 18) return "Good Afternoon";
    return "Good Evening";
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFirstName(data.firstName || "");
          setPhotoURL(data.photoURL || defaultProfilePic);
        } else {
          console.log("No such document!");
        }
      }
    }; 

    fetchUserData();
  }, []);

  return (
    <>
      {/* Sidebar for Larger Screens */}
      {!isMobile && (
        <Box
          sx={{
            position: "fixed",
            height: "100vh",
            width: '250px',
            overflowY: "auto",
            overflowX: "hidden",
            "& .pro-sidebar-inner": {
              background: `${colors.primary[400]} !important`,
            },
            "& .pro-icon-wrapper": {
              backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
              padding: "5px 35px 5px 20px !important",
            },
            "& .pro-inner-item:hover": {
              color: "#868dfb !important",
            },
            "& .pro-menu-item.active": {
              color: "#6870fa !important",
            },
          }}
        >
          <ProSidebar>
            <Menu iconShape="square">
              {/* User Info and Menu Items */}
              <Box mb="25px">
                <Typography 
                  variant="h5" 
                  color={colors.grey[100]} 
                  fontWeight="bold" 
                  textAlign="center" 
                  sx={{ paddingTop:"20%", mb: 1, fontSize: '1.5rem' }} // Increased font size
                >
                  USER DASHBOARD
                </Typography>
                <Box textAlign="center" mt={2}>
                  <img 
                    src={photoURL} 
                    alt="Profile" 
                    width="70" 
                    height="70" 
                    style={{ borderRadius: "50%", marginBottom: "10px",  userSelect: "none", // Prevent text selection
                      pointerEvents: "none",  }} 
                  />
                  <Typography 
                    variant="h6" 
                    color={colors.grey[100]} 
                    fontWeight="bold" 
                    sx={{ fontSize: '0.9rem', m: "10px 0" }}
                  >
                    {firstName ? `${getGreeting()}, ${firstName}` : getGreeting()}
                  </Typography>
                </Box>
              </Box>

              {/* Menu Items */}
              <Box paddingLeft="7%" paddingTop="1%">
                <Item title="Occurrences" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
                <Item title="Notifications/Tracking" to="/notifications" icon={<NotificationsOutlinedIcon />} selected={selected} setSelected={setSelected} />
                <Item title="Profile" to="/profile" icon={<PersonOutlinedIcon />} selected={selected} setSelected={setSelected} />
                <Item title="FAQ" to="/faq" icon={<HelpOutlineOutlinedIcon />} selected={selected} setSelected={setSelected} />
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      )}

      {/* Bottom Floating Menu for Mobile */}
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: colors.primary[400],
            borderRadius: "16px",
            display: 'flex',
            justifyContent: "space-around",
            width: "90%",
            boxShadow: "0px 4px 20px rgba(0,0,0,0.3)",
            padding: "10px 0",
          }}
        >
          <IconButton component={Link} to="/" onClick={() => setSelected("Dashboard")}>
            <HomeOutlinedIcon />
          </IconButton>
          <IconButton component={Link} to="/notifications" onClick={() => setSelected("Notifications/Tracking")}>
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton component={Link} to="/profile" onClick={() => setSelected("Profile")}>
            <PersonOutlinedIcon />
          </IconButton>
          <IconButton component={Link} to="/faq" onClick={() => setSelected("FAQ")}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default UserSidebar;
