import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar"; // Admin Sidebar
import UserSidebar from "./scenes/user/global/UserSiderbar"; // User Sidebar
import Dashboard from "./scenes/admin/dashboard"; // Admin Dashboard
import UserDashboard from "./scenes/user/UserDashboard"; // User Dashboard
import ReportTracking from "./scenes/user/reportTracking/ReportTracking"; // User Report Tracking
import Notifications from "./scenes/user/notifications/Notifications"; // User Notifications
import Profile from "./scenes/user/profile/Profile"; // User Profile
import Login from "./scenes/auth/login";
import ForgotPassword from "./scenes/auth/forgotPassword";
import Signup from "./scenes/auth/signUp";
import Calendar from "./scenes/admin/calender/calender"; // Admin Calendar
import ManageReports from "./scenes/admin/managereports/managereports"; // Admin Manage Reports
import AdminNotifications from "./scenes/admin/notifications/notifications"; // Admin Notifications
import Users from "./scenes/admin/users/users"; // Admin Users
import ViewLogs from "./scenes/admin/viewlogs/viewlogs"; // Admin View Logs
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { auth, db } from "./firebase/firebaseConfig"; // Import Firebase config
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null); // Add state to store the user's role
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Firebase Auth Listener
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);

        // Fetch the user role from Firestore
        const userDoc = await getDoc(doc(db, "Users", user.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role); // Set the role based on Firestore data
        } else {
          console.error("User document not found");
        }
      } else {
        setUser(null);
        setRole(null);
      }
      setLoading(false); // Stop loading once Firebase checks auth state
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading screen while checking auth state
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* Conditionally render the sidebar based on the role */}
          {user && (role === "admin" ? <Sidebar isSidebar={isSidebar} /> : <UserSidebar isSidebar={isSidebar} />)}

          <main className="content">
            {user && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              {/* Public Routes */}
              {!user ? (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot" element={<ForgotPassword/>} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </>
              ) : (
                <>
                  {/* Protected Routes Based on Role */}
                  {role === "admin" ? (
                    <>
                      <Route path="/admin" element={<Dashboard />} /> {/* Admin Dashboard */}
                      <Route path="/calendar" element={<Calendar />} /> {/* Admin Calendar */}
                      <Route path="/manage-reports" element={<ManageReports />} /> {/* Admin Manage Reports */}
                      <Route path="/admin-notifications" element={<AdminNotifications />} /> {/* Admin Notifications */}
                      <Route path="/users" element={<Users />} /> {/* Admin Users */}
                      <Route path="/view-logs" element={<ViewLogs />} /> {/* Admin View Logs */}
                    </>
                  ) : (
                    <>
                      <Route path="/user-dashboard" element={<UserDashboard />} /> {/* User Dashboard */}
                      <Route path="/report-tracking" element={<ReportTracking />} /> {/* User Report Tracking */}
                      <Route path="/notifications" element={<Notifications />} /> {/* User Notifications */}
                      <Route path="/profile" element={<Profile />} /> {/* User Profile */}
                    </>
                  )}
                </>
              )}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
