import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { db, auth } from "../../firebase/firebaseConfig";
import { collection, addDoc, onSnapshot, query, where } from "firebase/firestore";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles"; // Add this line

const UserDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newOccurrence, setNewOccurrence] = useState({
    item: "",
    description: "",
    type: "",
    employerId: "",
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [occurrences, setOccurrences] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const predictiveItems = [
    'Runway Maintenance', 'STATION OPEN', 'RWY OPEN & SVC (serviceable)', 'RWY CLSD', 'UNSERVICEABILITY (U/S)',
    'EMG', 'STATION CLSD', 'NOTE', 'WX UPDATE', 'RWY CHG', 'Navigational Aids (NAVAIDs) Maintenance',
    'Communication Systems Maintenance', 'Airfield Lighting Maintenance', 'Control Tower Equipment Maintenance',
    'VIP Movement', 'Unusual Weather Phenomenon', 'Emergency Landing', 'Military Exercise', 'Airshow or Special Event',
    'Deviation from Flight Plan', 'Unauthorized Entry into Airspace', 'Loss of Communication',
    'Equipment Malfunction', 'Incorrect Readback', 'Near-Miss', 'Runway Incursion', 'Bird Strike',
    'Laser Strike', 'Fuel Dumping'
  ];

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const q = query(collection(db, "logs"), where("userId", "==", user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedOccurrences = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOccurrences(fetchedOccurrences);
      });
      return () => unsubscribe();
    } else {
      setOccurrences([]);
    }
  }, []);

  const handleOccurrenceSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      console.error("User is not authenticated. Cannot log occurrence.");
      return;
    }

    setConfirmDialogOpen(true); // Open the confirmation dialog
  };

  const handleConfirmSubmit = async () => {
    const user = auth.currentUser;

    try {
      await addDoc(collection(db, "logs"), {
        ...newOccurrence,
        userId: user.uid,
        userEmail: user.email,
        timestamp: new Date(),
        status: "pending",
      });
      setNewOccurrence({ item: "", description: "", type: "", employerId: "" });
      setConfirmDialogOpen(false);
    } catch (error) {
      console.error("Error logging occurrence:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "20px", marginLeft: isSmallScreen ? "0" : "250px", flexGrow: 1 }}>
      <Header title="USER DASHBOARD" subtitle="Manage your occurrences" />

      <Box sx={{ backgroundColor: colors.primary[400], p: "20px", borderRadius: "8px", flexGrow: 1 }}>
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          Log Occurrence
        </Typography>
        <form onSubmit={handleOccurrenceSubmit}>
          <Autocomplete
            options={predictiveItems}
            onChange={(event, value) => setNewOccurrence({ ...newOccurrence, item: value })}
            renderInput={(params) => (
              <TextField {...params} label="Item" required fullWidth />
            )}
          />
          <TextField
            label="Description"
            value={newOccurrence.description}
            onChange={(e) => setNewOccurrence({ ...newOccurrence, description: e.target.value })}
            required
            fullWidth
            sx={{ marginTop: "10px" }}
          />
          <Select
            value={newOccurrence.type}
            onChange={(e) => setNewOccurrence({ ...newOccurrence, type: e.target.value })}
            required
            displayEmpty
            fullWidth
            sx={{ marginTop: "10px" }}
          >
            <MenuItem value="">
              <em>Type of Entry</em>
            </MenuItem>
            <MenuItem value="maintenance">Maintenance</MenuItem>
            <MenuItem value="incident">Incident</MenuItem>
            <MenuItem value="irregularity">Irregularity</MenuItem>
          </Select>
          <TextField
            label="Employer ID"
            value={newOccurrence.employerId}
            onChange={(e) => setNewOccurrence({ ...newOccurrence, employerId: e.target.value })}
            required
            fullWidth
            sx={{ marginTop: "10px" }}
          />
          <Button type="submit" variant="contained" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], marginTop: "20px" }}>
            Submit
          </Button>
        </form>
      </Box>

      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirm Log Occurrence</DialogTitle>
        <DialogContent>
          <Typography>Item: {newOccurrence.item}</Typography>
          <Typography>Description: {newOccurrence.description}</Typography>
          <Typography>Type of Entry: {newOccurrence.type}</Typography>
          <Typography>Employer ID: {newOccurrence.employerId}</Typography>
          <Typography>Please check all information and confirm if you want to log this occurrence.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleConfirmSubmit} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserDashboard;
