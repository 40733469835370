import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useTheme, useMediaQuery, Modal } from "@mui/material";
import { db, auth } from "../../../firebase/firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { tokens } from "../../../theme";

export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [occurrences, setOccurrences] = useState([]);
  const [selectedOccurrence, setSelectedOccurrence] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userEmail = user.email;
      const qNotifications = query(collection(db, "Notifications"), where("email", "==", userEmail));
      const qOccurrences = query(collection(db, "logs"), where("userId", "==", user.uid));

      const unsubscribeNotifications = onSnapshot(qNotifications, (snapshot) => {
        const fetchedNotifications = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(fetchedNotifications);
      });

      const unsubscribeOccurrences = onSnapshot(qOccurrences, (snapshot) => {
        const fetchedOccurrences = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOccurrences(fetchedOccurrences);
      });

      return () => {
        unsubscribeNotifications();
        unsubscribeOccurrences();
      };
    }
  }, []);

  const openModal = (occurrence) => {
    setSelectedOccurrence(occurrence);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOccurrence(null);
  };

  return (
    <Box sx={{
      padding: '20px',
      marginLeft: isSmallScreen ? '0' : '250px',
      flexGrow: 1,
      marginTop: '20px',
      display: 'flex',
      flexDirection: isSmallScreen ? 'column' : 'row',
      gap: '20px',
    }}>
      {/* Notifications Section */}
      <Box sx={{
        flex: 1,
        backgroundColor: colors.primary[400],
        borderRadius: '8px',
        padding: '20px',
        boxShadow: 2,
      }}>
        <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
          NOTIFICATIONS
        </Typography>
        <Typography variant="subtitle1" color={colors.grey[300]} marginY="10px">
          Keep track of important updates and messages from admins regarding your logs.
        </Typography>
        
        {notifications.map((notification) => (
          <Box key={notification.id} sx={{
            backgroundColor: colors.primary[500],
            borderRadius: '5px',
            padding: '10px',
            marginBottom: '10px',
            border: `1px solid ${colors.grey[600]}`,
          }}>
            <Typography variant="body2" color="white">
              {notification.message}
            </Typography>
            <Typography variant="caption" color={colors.grey[400]}>
              {new Date(notification.timestamp.seconds * 1000).toLocaleString()}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Logged Occurrences Section */}
      <Box sx={{
        flex: 1,
        backgroundColor: colors.primary[400],
        borderRadius: '8px',
        padding: '20px',
        boxShadow: 2,
      }}>
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          Your Logged Occurrences
        </Typography>
        <ul>
          {occurrences.length > 0 ? (
            occurrences.map((occurrence) => (
              <li key={occurrence.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                  <strong>{occurrence.item}</strong>: {occurrence.type} - {new Date(occurrence.timestamp.seconds * 1000).toLocaleString()}
                </div>
                <Button onClick={() => openModal(occurrence)} sx={{ color: 'white' }}>
                  <ArrowForwardIcon />
                </Button>
              </li>
            ))
          ) : (
            <Typography color={colors.grey[100]}>No logged occurrences found.</Typography>
          )}
        </ul>
      </Box>

      {/* Modal for Occurrence Details */}
      <Modal open={modalOpen} onClose={closeModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '90%',
          maxWidth: 500,
        }}>
          <Typography variant="h6" gutterBottom>Occurrence Details</Typography>
          {selectedOccurrence && (
            <Box>
              <Typography><strong>Item:</strong> {selectedOccurrence.item}</Typography>
              <Typography><strong>Description:</strong> {selectedOccurrence.description}</Typography>
              <Typography><strong>Employer ID:</strong> {selectedOccurrence.employerId}</Typography>
              <Typography><strong>Status:</strong> {selectedOccurrence.status}</Typography>
              <Typography><strong>Date Posted:</strong> {new Date(selectedOccurrence.timestamp.seconds * 1000).toLocaleString()}</Typography>
            </Box>
          )}
          <Button onClick={closeModal} variant="contained" color="primary" sx={{ marginTop: 2 }}>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Notifications;
