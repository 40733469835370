import React, { useState } from "react";
import { db } from "../../../firebase/firebaseConfig"; 
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { TextField, Button, Typography, Box } from "@mui/material";
import SendIcon from '@mui/icons-material/Send'; 

const SendNotification = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleSendNotification = async () => {
    try {
     
      await addDoc(collection(db, "Notifications"), {
        email: email, 
        message: message,
        timestamp: serverTimestamp(),
      });
      setStatus("Notification sent successfully!");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error sending notification:", error.message);
      setStatus(`Failed to send notification: ${error.message}`);
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h5">Send Notification</Typography>
      <TextField
        label="User Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
      <TextField
        label="Notification Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
        multiline
        rows={4}
        sx={{ marginBottom: '10px' }}
      />
      <Button
        variant="contained"
        color="secondary" 
        onClick={handleSendNotification}
        startIcon={<SendIcon />} 
      >
        Send Notification
      </Button>
      {status && <Typography color="green">{status}</Typography>}
    </Box>
  );
};

export default SendNotification;
