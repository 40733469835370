import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Typography, Box, Modal, Button, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { tokens } from '../../../theme';

const CalendarView = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [logs, setLogs] = useState([]);
  const [value, setValue] = useState(new Date());
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logsCollection = collection(db, 'logs');
        const logSnapshot = await getDocs(logsCollection);
        const logsList = logSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setLogs(logsList);
      } catch (error) {
        console.error('Error fetching logs:', error.message);
      }
    };

    fetchLogs();
  }, []);

  const handleDateChange = (date) => {
    setValue(date);
    const selectedDate = format(date, 'dd/MM/yyyy');
    const filteredLogs = logs.filter((log) =>
      log.timestamp && format(new Date(log.timestamp.seconds * 1000), 'dd/MM/yyyy') === selectedDate
    );
    setSelectedLogs(filteredLogs);
    if (filteredLogs.length > 0) {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedLogs([]);
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom color={colors.blueAccent[500]}>
        Logs Calendar
      </Typography>
      <Calendar
        onChange={handleDateChange}
        value={value}
        className="custom-calendar" // Added custom class for further styling
      />
      <Modal
        open={modalOpen}
        onClose={closeModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            bgcolor: colors.primary[400],
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: '80%',
            maxWidth: 800,
            border: `2px solid ${colors.blueAccent[500]}`,
          }}
        >
          <Typography variant="h6" gutterBottom color={colors.grey[100]}>
            Logs for {format(value, 'dd/MM/yyyy')}:
          </Typography>
          {selectedLogs.map((log) => (
            <Box
              key={log.id}
              sx={{
                marginBottom: '10px',
                padding: '15px',
                border: `1px solid ${colors.grey[300]}`,
                backgroundColor: colors.primary[300],
                borderRadius: '4px',
              }}
            >
              <Typography color={colors.grey[100]}>
                <strong>Item:</strong> {log.item}
              </Typography>
              <Typography color={colors.grey[100]}>
                <strong>Comments:</strong> {log.comments}
              </Typography>
              <Typography color={log.status === 'pending' ? 'orange' : 'green'}>
                <strong>Status:</strong> {log.status}
              </Typography>
            </Box>
          ))}
          <Button
            onClick={closeModal}
            variant="contained"
            color="primary"
            sx={{ marginTop: 2, backgroundColor: colors.blueAccent[700] }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      {/* Add custom styles for calendar */}
      <style>
        {`
          .custom-calendar {
            width: 100%;
            height: auto;
            border: 1px solid ${colors.grey[300]};
            border-radius: 10px;
            background-color: ${colors.primary[400]};
          }
          
          .react-calendar__tile--active {
            background-color: ${colors.blueAccent[500]} !important;
            color: ${colors.grey[100]} !important;
            border-radius: 8px;
          }
          
          .react-calendar__tile {
            padding: 15px;
            text-align: center;
            border-radius: 8px;
            font-weight: bold;
          }

          .react-calendar__month-view__weekdays {
            color: ${colors.grey[300]};
            font-size: 1.2em;
          }

          .react-calendar__tile--now {
            background: ${colors.greenAccent[600]};
            color: white;
          }
        `}
      </style>
    </Box>
  );
};

export default CalendarView;
