import React, { useState } from 'react';
import './signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/firebaseConfig'; 
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth'; // Import signOut to prevent automatic login
import { setDoc, doc } from 'firebase/firestore'; 
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [station, setStation] = useState('');
  const [designation, setDesignation] = useState('');
  const [position, setPosition] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (fname.trim().length < 2) {
      errors.fname = "First name must be at least 2 characters long";
      valid = false;
    }

    if (lname.trim().length < 2) {
      errors.lname = "Last name must be at least 2 characters long";
      valid = false;
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      errors.email = "Please enter a valid email address";
      valid = false;
    }

    if (password.length < 8 || !password.match(/^(?=.*[A-Za-z])(?=.*\d).+$/)) {
      errors.password = "Password must be at least 8 characters long and contain both letters and numbers";
      valid = false;
    }

    if (password !== rePassword) {
      errors.rePassword = "Passwords do not match";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Sign-up form submitted');

    if (!validateForm()) {
      toast.error("Please fix the errors in the form", { position: 'top-center' });
      return;
    }

    try {
      // Sign up the user using Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log('User created:', user);

      // Create user document in Firestore
      await setDoc(doc(db, 'Users', user.uid), {
        email: user.email,
        firstName: fname,
        lastName: lname,
        employeeId,
        station,
        designation,
        position,
        status: 'unactive',
        photo: '',
        role: 'user',
      });

      console.log("User document successfully created in Firestore");

      // Sign out the user right after creation to prevent login
      await signOut(auth);

      // Show success toast notification
      toast.success('You have successfully signed up. Please wait for Administrator verification.', {
        position: 'top-center',
      });
      
      // Navigate to login or home page after sign-up
      navigate('/login');
    } catch (err) {
      console.error("Sign-up error:", err);
      toast.error(`Error: ${err.message}`, { position: 'bottom-center' });
    }
  };

  return (
    <div className="addUser" style={{ maxWidth: '500px', margin: 'auto' }}>
      <h3>Sign Up</h3>
      <form className="addUserForm" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="fname">First Name:</label>
          <input type="text" id="fname" value={fname} onChange={(e) => setFname(e.target.value)} required />
          {errors.fname && <p className="error-message">{errors.fname}</p>}

          <label htmlFor="lname">Last Name:</label>
          <input type="text" id="lname" value={lname} onChange={(e) => setLname(e.target.value)} required />
          {errors.lname && <p className="error-message">{errors.lname}</p>}

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          {errors.email && <p className="error-message">{errors.email}</p>}

          <label htmlFor="employeeId">Employee ID:</label>
          <input type="text" id="employeeId" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} required />

          <label htmlFor="station">Station:</label>
          <input type="text" id="station" value={station} onChange={(e) => setStation(e.target.value)} required />

          <label htmlFor="designation">Designation:</label>
          <input type="text" id="designation" value={designation} onChange={(e) => setDesignation(e.target.value)} required />

          <label htmlFor="position">Position:</label>
          <input type="text" id="position" value={position} onChange={(e) => setPosition(e.target.value)} required />

          <label htmlFor="password">Password:</label>
          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          {errors.password && <p className="error-message">{errors.password}</p>}

          <label htmlFor="rePassword">Re-enter Password:</label>
          <input type="password" id="rePassword" value={rePassword} onChange={(e) => setRePassword(e.target.value)} required />
          {errors.rePassword && <p className="error-message">{errors.rePassword}</p>}

          <button type="submit" className="btn btn-success">Sign Up</button>
        </div>
      </form>
      <div className="login">
        <p>Already have an Account?</p>
        <Link to="/login" className="btn btn-primary">Login</Link>
      </div>
    </div>
  );
};

export default SignUp;
