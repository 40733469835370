import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { doc, getDoc } from 'firebase/firestore'; // Firestore functions
import SignInwithGoogle from './signInWithGoogle';

const Login = () => {
    const navigate = useNavigate(); 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            // Authenticate user
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Fetch the user's data from Firestore
            const userDoc = await getDoc(doc(db, 'Users', user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const userStatus = userData.status;
                const userRole = userData.role;

                // Check if the user's account is active
                if (userStatus === 'active') {
                    // Allow login and redirect based on user role
                    if (userRole === 'admin') {
                        navigate('/admin'); // Admin Dashboard route
                    } else {
                        navigate('/user-dashboard'); // User Dashboard route
                    }

                    toast.success("Logged in successfully", { position: "top-center" });
                } else {
                    // If the user is not active, show a notification
                    toast.error("Please wait for Administrator Activation", { position: "top-center" });
                    await auth.signOut(); // Sign the user out if status is not active
                }
            } else {
                throw new Error("User data not found.");
            }
        } catch (err) {
            console.error("Login error:", err.message);
            setError('Invalid username/password');
            toast.error(err.message, {
                position: "bottom-center",
            });
        }
    };

    return (
        <div className="addUser">
            <h3>Login</h3>
            <form className="addUserForm" onSubmit={handleSubmit}>
                <div className="inputGroup">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="off"
                        placeholder="Enter your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="off"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit" className="btn btn-success">
                        Login
                    </button>
                </div>
                {error && <p className="error-message">{error}</p>}
            </form>
            <div className="login">
                <p>Don't have an Account?</p>
                <Link to="/signup" className="btn btn-primary">
                    Register
                </Link>
            </div>
            <div className="forgot-password">
                <Link to="/forgot" style={{ textDecoration: 'none' }}>Forgot Password?</Link>
            </div>
            <SignInwithGoogle />
        </div>
    );
};

export default Login;
