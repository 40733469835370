import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth } from "../../firebase/firebaseConfig"; // Import your Firebase auth

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out the user
      console.log("User signed out successfully.");
      // Optionally, redirect or perform any additional actions after logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box />

      <Box display="flex" alignItems="center">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleLogout} sx={{ display: 'flex', alignItems: 'center' }}>
          <LogoutIcon />
          <Typography variant="body1" sx={{ ml: 1 }}>Logout</Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
