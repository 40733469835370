import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebaseConfig";
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { auth } from '../../../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  useMediaQuery,
  TableContainer,
  Button,
  Modal,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle"; // Import the AddCircle icon
import EditIcon from "@mui/icons-material/Edit"; // Import the Edit icon
import { toast } from 'react-toastify';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [modalOpen, setModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({ email: '', password: '', firstName: '', lastName: '', role: 'user', status: 'active' });
  const [editingRole, setEditingRole] = useState({ userId: null, role: '' });

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, "Users");
      const userSnapshot = await getDocs(usersCollection);
      const usersList = userSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Sort users by role (admin at top)
      usersList.sort((a, b) => {
        if (a.role === 'admin' && b.role !== 'admin') return -1;
        if (a.role !== 'admin' && b.role === 'admin') return 1;
        return 0;
      });

      setUsers(usersList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDeleteUser = async (userIdInFirestore) => {
    try {
      await deleteDoc(doc(db, "Users", userIdInFirestore));
      setUsers(users.filter((user) => user.id !== userIdInFirestore));
      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error.message);
      toast.error("Failed to delete user");
    }
  };

  const handleToggleUserStatus = async (userIdInFirestore, currentStatus, email) => {
    try {
      const newStatus = currentStatus === "active" ? "unactive" : "active";
      await updateDoc(doc(db, "Users", userIdInFirestore), { status: newStatus });
      setUsers(users.map(user => user.id === userIdInFirestore ? { ...user, status: newStatus } : user));
      toast.success(`User ${newStatus === "active" ? "activated" : "deactivated"} successfully`);
    } catch (error) {
      console.error("Error toggling user status:", error.message);
      toast.error("Failed to toggle user status");
    }
  };

  const handleAddUser = async () => {
    const { email, password, firstName, lastName, role } = newUser;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      await db.collection("Users").doc(userId).set({ firstName, lastName, role, email, status: 'active' });
      toast.success("User added successfully");

      setNewUser({ email: '', password: '', firstName: '', lastName: '', role: 'user', status: 'active' });
      setModalOpen(false);
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error.message);
      toast.error("Failed to add user");
    }
  };

  const handleEditRole = (userId, currentRole) => {
    setEditingRole({ userId, role: currentRole });
  };

  const handleRoleChange = async (userId) => {
    try {
      await updateDoc(doc(db, "Users", userId), { role: editingRole.role });
      setUsers(users.map(user => user.id === userId ? { ...user, role: editingRole.role } : user));
      setEditingRole({ userId: null, role: '' });
      toast.success("Role updated successfully");
    } catch (error) {
      console.error("Error updating role:", error.message);
      toast.error("Failed to update role");
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>Manage Users</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
        startIcon={<AddCircleIcon sx={{ fontSize: 24 }} />} // Add the icon with increased size
        sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#1976d2' }} // Make it more noticeable
      >
        Add User
      </Button>
      <Paper elevation={3} sx={{ padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>
                    {editingRole.userId === user.id ? (
                      <TextField
                        value={editingRole.role}
                        onChange={(e) => setEditingRole({ ...editingRole, role: e.target.value })}
                        onBlur={() => handleRoleChange(user.id)}
                        autoFocus
                      />
                    ) : (
                      <>
                        {user.role}
                        <IconButton onClick={() => handleEditRole(user.id, user.role)} sx={{ color: 'white' }}>
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color={user.status === 'active' ? 'secondary' : 'primary'}
                      onClick={() => handleToggleUserStatus(user.id, user.status, user.email)}
                    >
                      {user.status === 'active' ? 'Deactivate' : 'Activate'}
                    </Button>
                    <IconButton onClick={() => handleDeleteUser(user.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Add User Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '90%',
          maxWidth: 500,
          margin: 'auto',
          marginTop: '100px',
        }}>
          <Typography variant="h6" gutterBottom>Add New User</Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          />
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            margin="normal"
            value={newUser.firstName}
            onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
          />
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            margin="normal"
            value={newUser.lastName}
            onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
          />
          <TextField
            fullWidth
            label="Role"
            variant="outlined"
            margin="normal"
            value={newUser.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
          />
          <Button variant="contained" color="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ManageUsers;
